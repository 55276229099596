<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat>
      <v-card-title>
        {{ displayReportTitle }}
        <v-spacer></v-spacer>
        <AppTooltipBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-printer"
          tooltip="打印报告"
          @click="printReport"
        />
        <AppMenuBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-download"
          tooltip="下载报告"
        >
          <v-list>
            <v-list-item @click="displayHtmlAsImage">
              <v-icon class="mr-2">mdi-file-image</v-icon>
              下载为图片
            </v-list-item>
            <v-list-item @click="downloadAsPdf">
              <v-icon class="mr-2">mdi-file-pdf</v-icon>
              下载为PDF报告
            </v-list-item>
          </v-list>
        </AppMenuBtn>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="mr-2"
                  icon
                  large
                  color="primary"
                  v-on="{ ...menu, ...tooltip }"
                  v-bind="attrs"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <span>更多</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="isShowCurve = true">
              <v-icon small class="mr-2">mdi-chart-bell-curve</v-icon>
              分布曲线
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height:70vh;overflow:auto;">
        <div id="reportDiv" v-html="displayErrorMsg || displayReportHtml"></div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <AppDialog
      action-divider
      v-model="isShowImageDialog"
      overflow-height="300px"
      cancel-text="关闭"
    >
      <img :src="imageSrc" style="max-width:100%;" />
      <template #action-ex>
        <span class="ml-4">长按或右击保存图片</span>
      </template>
    </AppDialog>
    <v-dialog v-model="isShowCurve" max-width="900px" class="mx-auto">
      <v-card>
        <GroupReportCharts
          :case-guid-list="caseGuidList"
          :display-title="lbDispName"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="isShowCurve = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppMessageBox v-model="dialogErrorMsg" title="发生错误" />
  </div>
</template>

<script>
import GroupReportCharts from "@/components/report/GroupReportCharts";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import printJS from "print-js";
import { generateGroupReportHtml, saveGroupReportToPdf } from "@/api/report";
import { downloadFile } from "@/utils/download";
import { getReportImageBase64 } from "@/utils/reportCanvas";

export default {
  components: {
    GroupReportCharts,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox,
    AppTooltipBtn,
    AppMenuBtn
  },

  props: {
    caseGuidList: {
      type: Array
    }
  },

  data() {
    return {
      isLoading: false,
      reportTitle: "",
      reportHtml: "",
      reportFileName: "",
      lbDispName: "",
      imageSrc: "",
      errorMsg: "",
      isShowCurve: false,
      // dialog
      isShowImageDialog: false,
      dialogErrorMsg: ""
    };
  },

  watch: {
    caseGuidList(newList) {
      return this.generateGroupReport(newList);
    }
  },

  computed: {
    displayReportTitle() {
      return (
        this.reportTitle || (this.errorMsg ? "无法生成报告" : "报告生成中...")
      );
    },
    displayReportHtml() {
      return this.reportHtml;
    },
    displayErrorMsg() {
      return this.errorMsg;
    }
  },

  methods: {
    resetData() {
      this.reportTitle = "";
      this.reportHtml = "";
      this.errorMsg = "";
    },
    async generateGroupReport(guidList) {
      if (guidList && guidList.length) {
        try {
          this.isLoading = true;
          let groupReport = await generateGroupReportHtml(guidList);
          this.reportTitle = groupReport.reportTitle;
          this.reportHtml = groupReport.reportHtml;
          this.reportFileName = groupReport.reportFileName;
          this.lbDispName = groupReport.lbDispName;
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      } else {
        // guid 和 guidList 为空，表示报告的 dialog 被关闭
        this.resetData();
      }
    },
    // 生成图片
    async displayHtmlAsImage() {
      try {
        this.isLoading = true;
        let reportDom = document.getElementById("reportDiv");
        if (reportDom) {
          this.imageSrc = await getReportImageBase64(reportDom, 800);
          this.isShowImageDialog = true;
        }
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    // 下载 pdf
    async downloadAsPdf() {
      try {
        this.isLoading = true;
        let downloadPath = await saveGroupReportToPdf(this.caseGuidList);
        downloadFile(downloadPath, `${this.reportFileName}.pdf`);
      } catch (err) {
        this.errorMsg = err;
      }
      this.isLoading = false;
    },
    // 打印报告
    printReport() {
      printJS({
        printable: "reportDiv",
        type: "html",
        showModal: true
      });
    }
  },

  created() {
    this.generateGroupReport(this.caseGuidList);
  }
};
</script>
