<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat>
      <v-card-title>
        {{ displayTitle }} 团体分布曲线
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="currentValueMode"
          mandatory
          dense
          color="primary"
        >
          <v-btn>数值</v-btn>
          <v-btn>百分比</v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text>团体测评人次数： {{ caseGuidCount }}</v-card-text>
      <v-divider></v-divider>
      <v-card-text style="height:65vh;overflow:auto;">
        <v-row>
          <v-col cols="3">
            <v-list max-height="60vh" class="overflow-y-auto">
              <v-list-item-group
                v-model="currentCurveFrameIndex"
                mandatory
                color="primary"
              >
                <v-list-item
                  v-for="(fTitle, fIndex) in curveFrameTitles"
                  :key="fIndex"
                >
                  {{ fTitle }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="9">
            <div
              id="echartsmain"
              class="mx-auto"
              style="width:600px;height:400px;"
            ></div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import {
  drawGroupReportCharts,
  disposeChart
} from "@/utils/charts/chartsGroupReport";
import { generateGroupReportRawData } from "@/api/report";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    caseGuidList: {
      type: Array,
      required: true
    },
    displayTitle: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      reportRawData: {},
      currentCurveFrameIndex: 0,
      currentValueMode: 0,
      errorMsg: ""
    };
  },

  watch: {
    currentCurveFrameIndex() {
      disposeChart();
      this.drawWithEcharts();
    },
    currentValueMode() {
      this.drawWithEcharts();
    },
    caseGuidList(newVal) {
      if (!newVal || !newVal.length) {
        this.$nextTick(() => {
          disposeChart();
        });
      }
    }
  },

  computed: {
    caseGuidCount() {
      return this.caseGuidList ? this.caseGuidList.length : 0;
    },
    curveFrameTitles() {
      if (this.reportRawData && this.reportRawData.dataFrameList) {
        return this.reportRawData.dataFrameList.map(df => df.title);
      }
      return "";
    }
  },

  methods: {
    async fetchGroupReportRawData() {
      try {
        this.reportRawData = await generateGroupReportRawData(
          this.caseGuidList
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    // Echarts 图表
    drawWithEcharts() {
      let currentFrame = this.reportRawData.dataFrameList[
        this.currentCurveFrameIndex
      ];
      let hitPersonDetailsList = currentFrame.hitTestPersonIndexList.map(
        psnIndexList => {
          if (psnIndexList && psnIndexList.length) {
            let psnDetailsList = [];
            for (let psnIndex of psnIndexList) {
              psnDetailsList.push({
                ...this.reportRawData.personList[psnIndex]
              });
            }
            return {
              value: this.currentValueMode
                ? (
                    (psnIndexList.length / this.reportRawData.sampleCount) *
                    100
                  ).toFixed(2)
                : psnIndexList.length,
              hitList: psnDetailsList
            };
          }
          return {
            value: 0,
            hitList: null
          };
        }
      );
      drawGroupReportCharts(document.getElementById("echartsmain"), {
        chartTitle: currentFrame.title,
        chartSubtext: "测量人（次）",
        xAxisData: currentFrame.axisLabels,
        yAxisLabel: this.currentValueMode ? "{value}%" : "{value}",
        seriesData: hitPersonDetailsList,
        visualMarkData: {
          blockWidthList: currentFrame.classifiedBlockWidth,
          blockLabelList: currentFrame.classifiedBlockLabels,
          blockColorList: currentFrame.classifiedBlockColors.map(
            bc => `rgba(${bc.r}, ${bc.g}, ${bc.b}, ${bc.a})`
          )
        },
        tooltipCallback: (params, ticket, callback) => {
          let hitCount = params[0].data.value;
          let hitList = params[0].data.hitList;
          let hitCountHtml = this.currentValueMode
            ? `<p>此分段内案例占比：${hitCount}%</p>`
            : `<p>此分段内案例数：${hitCount}</p>`;
          let hitListHtml = "";
          if (hitList && hitList.length) {
            for (let hit of hitList) {
              hitListHtml += `<li style="list-style:none;margin-right:25px;">
                ${hit.loginIdentity} - ${hit.personName}
              </li>`;
            }
            hitListHtml = `
              <ul style="max-height:75vh;display:flex;flex-direction:column;flex-wrap:wrap;
                         justify-content:flex-start;align-content:flex-start;
                         margin:0;padding:0;">
                ${hitListHtml}
              </ul>`;
          }
          let tooltipHtml = `
            <div style="width:auto;max-height:80vh;">
              ${hitCountHtml}
              ${hitListHtml}
            </div>`;
          callback(ticket, tooltipHtml);
        },
        tooltipPosition: () => {
          return { top: 30 };
        }
      });
    }
  },

  async created() {
    this.isLoading = true;
    await this.fetchGroupReportRawData(this.caseGuidList);
    this.drawWithEcharts();
    this.isLoading = false;
  }
};
</script>
