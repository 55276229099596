<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <AppDialog
      v-model="isShowDialog"
      persistent
      size="small"
      :title="isNewPwd ? '设置新密码' : '修改密码'"
      color="primary"
      cancel-text="关闭"
      action-text="确定修改"
      :loading="isDialogLoading"
      @confirm="saveNewPwdConfirmed"
      @closed="changePasswordClosed"
    >
      <v-text-field
        v-if="!isNewPwd"
        type="password"
        label="当前密码"
        v-model="userPwd"
      ></v-text-field>
      <v-text-field
        type="password"
        label="新密码"
        v-model="userNewPwd"
        :rules="fieldRules.userNewPwd"
      ></v-text-field>
      <v-text-field
        type="password"
        label="确认新密码"
        v-model="userNewPwdRepeat"
        :rules="fieldRules.userNewPwdRepeat"
      ></v-text-field>
    </AppDialog>
    <AppMessageBox v-model="successMsg" title="成功" />
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import { changeOrCreateUserPwd } from "@/api/user";
import { bulkUpdatePersonPassword } from "@/api/person";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    userGuid: {
      type: String
    },
    personGuidList: {
      type: Array
    },
    isNewPwd: {
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      errorMsg: "",
      successMsg: "",
      isShowDialog: false,
      userPwd: "",
      userNewPwd: "",
      userNewPwdRepeat: "",
      fieldRules: {
        userNewPwd: [val => (val || "").length >= 6 || "新密码不能少于6位"],
        userNewPwdRepeat: [
          val =>
            (val || "").length < this.userNewPwd.length ||
            val === this.userNewPwd ||
            "两次输入的密码不匹配"
        ]
      }
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (!newVal) {
        this.$emit("closed");
      }
    }
  },

  methods: {
    async saveNewPwdConfirmed() {
      if (this.userNewPwd && this.userNewPwdRepeat) {
        if (this.userNewPwd !== this.userNewPwdRepeat) {
          this.errorMsg = "两次输入的新密码不同";
          return;
        }
        if (this.userGuid) {
          await this.saveNewPwdToUser();
        } else if (this.personGuidList && this.personGuidList.length) {
          await this.saveNewPwdToPersonList();
        }
      }
    },
    async saveNewPwdToUser() {
      try {
        this.isDialogLoading = true;
        await changeOrCreateUserPwd(
          this.userGuid,
          this.userPwd,
          this.userNewPwd
        );
        this.successMsg = "用户密码修改成功！";
        this.isShowDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    async saveNewPwdToPersonList() {
      try {
        this.isDialogLoading = true;
        await bulkUpdatePersonPassword(
          this.personGuidList,
          false,
          this.userNewPwd
        );
        this.successMsg = "被试人员密码成功重置！";
        this.isShowDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    changePasswordClosed() {
      // 重置控件
      this.userPwd = "";
      this.userNewPwd = "";
      this.userNewPwdRepeat = "";
    }
  }
};
</script>
