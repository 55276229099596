<template>
  <div>
    <v-card flat>
      <AppLoadingSpinner v-model="isLoading" />
      <v-card-title>
        {{ displayReportTitle }}
        <v-spacer></v-spacer>
        <AppTooltipBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-signature-freehand"
          tooltip="签署报告"
          @click="isShowSignReportDialog = true"
        />
        <AppTooltipBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-printer"
          tooltip="打印报告"
          @click="printReport"
        />
        <AppMenuBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-download"
          tooltip="下载报告"
        >
          <v-list>
            <v-list-item @click="displayHtmlAsImage">
              <v-icon class="mr-2">mdi-file-image</v-icon>
              下载为图片
            </v-list-item>
            <v-list-item @click="downloadAsPdf">
              <v-icon class="mr-2">mdi-file-pdf</v-icon>
              下载为PDF报告
            </v-list-item>
            <v-list-item @click="downloadAsWord">
              <v-icon class="mr-2">mdi-file-word</v-icon>
              下载为WORD报告
            </v-list-item>
          </v-list>
        </AppMenuBtn>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="mr-2"
                  icon
                  large
                  color="primary"
                  v-on="{ ...menu, ...tooltip }"
                  v-bind="attrs"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <span>更多</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="showReportEditDialog">
              <v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>
              修改结论描述
            </v-list-item>
            <v-list-item @click="isShowResetReportDialog = true">
              <v-icon class="mr-2">mdi-file-restore-outline</v-icon>
              重置修改
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height:70vh;overflow:auto;">
        <div id="reportDiv" v-html="errorMsg || displayReportHtml"></div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <AppDialog
      v-model="isShowSignReportDialog"
      persistent
      title="签署报告"
      size="small"
      color="primary"
      action-text="确认签署"
      :loading="isDialogLoading"
      @confirm="signReportConfirmed(selectedReporter)"
    >
      <template #action-ex>
        <v-btn text color="error" @click="signReportConfirmed('')">
          撤销签署
        </v-btn>
      </template>
      <v-select
        :items="reporterCandidates"
        v-model="selectedReporter"
        label="选择一个署名"
        hint="署名可在“设置”中进行修改"
      ></v-select>
    </AppDialog>
    <AppDialog
      v-model="isShowReportEditDialog"
      persistent
      title="修改结论描述"
      overflow-height="400px"
      color="green"
      action-text="保存"
      :loading="isDialogLoading"
      @confirm="editReportBodyConfirmed"
      @closed="editReportBodyClosed"
    >
      <v-lazy>
        <v-textarea
          autofocus
          filled
          single-line
          rows="12"
          no-resize
          counter
          :counter-value="reportEditorCounter"
          v-model="editingReportBody"
        ></v-textarea>
      </v-lazy>
    </AppDialog>
    <AppDialog
      action-divider
      v-model="isShowImageDialog"
      overflow-height="300px"
      cancel-text="关闭"
    >
      <img :src="imageSrc" style="max-width:100%;" />
      <template #action-ex>
        <span class="ml-4">长按或右击保存图片</span>
      </template>
    </AppDialog>
    <AppDialog
      v-model="isShowResetReportDialog"
      size="small"
      title="确定要重新自动生成报告吗？"
      color="primary"
      action-text="重新生成"
      :loading="isDialogLoading"
      @confirm="resetReportBodyConfirmed"
    >
      重新生成后，之前的修改将全部删除。<br />删除后不可恢复！
    </AppDialog>
    <AppMessageBox v-model="dialogErrorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppMessageBox from "@/components/AppMessageBox";
import printJS from "print-js";
import {
  fetchReportHtml,
  updateReportBody,
  saveReportToFile,
  updateReportSign
} from "@/api/report";
import { downloadFile } from "@/utils/download";
import { getReportImageBase64 } from "@/utils/reportCanvas";
import { reportExportFormatType } from "@/utils/constants/report";

export default {
  components: {
    AppLoadingSpinner,
    AppDialog,
    AppTooltipBtn,
    AppMenuBtn,
    AppMessageBox
  },

  props: {
    caseGuid: {
      type: String
    },
    mode: {
      type: String,
      required: true
    },
    reporterCandidates: {
      type: Array,
      requried: true
    }
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      reportTitle: "",
      reportHtml: "",
      reportBody: "",
      reportFileName: "",
      selectedReporter: "",
      imageSrc: "",
      errorMsg: "",
      dialogErrorMsg: "",
      // dialog
      isShowSignReportDialog: false,
      isShowReportEditDialog: false,
      isShowImageDialog: false,
      isShowResetReportDialog: false,
      editingReportBody: "",
      reportEditTextAreaWidth: null
    };
  },

  watch: {
    caseGuid(newGuid) {
      return this.generateAdminReport(newGuid);
    }
  },

  computed: {
    displayReportTitle() {
      return (
        this.reportTitle || (this.errorMsg ? "无法生成报告" : "报告生成中...")
      );
    },
    displayReportHtml() {
      return this.reportHtml;
    }
  },

  methods: {
    resetData() {
      this.reportTitle = "";
      this.reportHtml = "";
      this.reportFileName = "";
      this.errorMsg = "";
    },
    async generateAdminReport(guid) {
      if (guid && guid.length) {
        try {
          this.isLoading = true;
          let report = await fetchReportHtml(guid, this.mode);
          this.reportTitle = report.reportTitle;
          this.reportHtml = report.reportHtml;
          this.reportBody = report.reportBody;
          this.reportFileName = report.reportFileName;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      } else {
        // guid 和 objList 为空，表示报告的 dialog 被关闭
        this.resetData();
      }
    },
    async updateToReportBody(reportBodyStr) {
      try {
        let bodyLines = null;
        if (reportBodyStr) {
          bodyLines = reportBodyStr.split("\n");
        }
        await updateReportBody(this.caseGuid, {
          reportBodyLines: bodyLines,
          reportModeName: this.mode
        });
        // 刷新
        await this.generateAdminReport(this.caseGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    // 修改 reportbody
    showReportEditDialog() {
      // 强制刷新textarea
      this.editingReportBody = this.reportBody;
      this.isShowReportEditDialog = true;
    },
    reportEditorCounter(contents) {
      let ignoreEscape = contents.replace(/[\t\n\r]+/g, "");
      return `字符数: ${ignoreEscape.length}`;
    },
    async editReportBodyConfirmed() {
      this.isDialogLoading = true;
      await this.updateToReportBody(this.editingReportBody);
      this.reportBody = this.editingReportBody;
      this.isShowReportEditDialog = false;
      this.isDialogLoading = false;
    },
    editReportBodyClosed() {
      this.editingReportBody = "";
    },
    // 重置 reportbody
    async resetReportBodyConfirmed() {
      this.isDialogLoading = true;
      await this.updateToReportBody("");
      this.isShowResetReportDialog = false;
      this.isDialogLoading = false;
    },
    // 生成图片
    async displayHtmlAsImage() {
      try {
        this.isLoading = true;
        let reportDom = document.getElementById("reportDiv");
        if (reportDom) {
          this.imageSrc = await getReportImageBase64(reportDom, 800);
          this.isShowImageDialog = true;
        }
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    // 下载报告到文件
    downloadAsPdf() {
      this.downloadToFile(reportExportFormatType.pdf);
    },
    downloadAsWord() {
      this.downloadToFile(reportExportFormatType.word);
    },
    async downloadToFile(fileFormatType) {
      try {
        this.isLoading = true;
        let downloadPath = await saveReportToFile({
          caseGuid: this.caseGuid,
          reportModeName: this.mode,
          exportFormat: fileFormatType.name
        });
        downloadFile(
          downloadPath,
          `${this.reportFileName}${fileFormatType.ext}`
        );
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    // 打印报告
    printReport() {
      printJS({
        printable: "reportDiv",
        type: "html",
        showModal: true,
        font_size: "14px"
      });
    },
    // 签署报告
    async signReportConfirmed(newReporterName) {
      try {
        this.isDialogLoading = true;
        await updateReportSign(this.caseGuid, {
          reporterName: newReporterName
        });
        // 签署之后刷新报告
        await this.generateAdminReport(this.caseGuid);
        // 重置
        this.selectedReporter = "";
        this.isShowSignReportDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    }
  },

  created() {
    this.generateAdminReport(this.caseGuid);
  }
};
</script>
