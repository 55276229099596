const sortDirection = {
  asc: {
    value: "asc"
  },
  desc: {
    value: "desc"
  }
};

export function buildLimitOffsetObj(page, itemsPerPage) {
  return {
    offset: (page - 1) * itemsPerPage,
    limit: itemsPerPage
  };
}

export function buildOrderList(sortBy, sortDesc) {
  if (sortBy && sortBy.length) {
    let orderList = [];
    for (let i = 0; i < sortBy.length; i++) {
      orderList.push({
        fieldName: sortBy[i],
        orderDirection: sortDesc[i]
          ? sortDirection.desc.value
          : sortDirection.asc.value
      });
    }
    return orderList;
  }
}
