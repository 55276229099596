<template>
  <AppMenuBtn
    :btn-class="btnClass"
    :color="color"
    :icon="icon"
    :tooltip="tooltip"
    :label="label"
  >
    <v-list v-if="mmpiLbIdList.includes(lbId)">
      <v-list-item
        v-for="(mode, idx) in mmpiReportModeList"
        :key="idx"
        @click="btnClicked(mode.value)"
      >
        {{ mode.text }}
      </v-list-item>
    </v-list>
    <v-list v-else-if="nuclearLbIdList.includes(lbId)">
      <v-list-item
        v-for="(mode, idx) in nuclearReportModeList"
        :key="idx"
        @click="btnClicked(mode.value)"
      >
        {{ mode.text }}
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item
        v-for="(mode, idx) in reportModeList"
        :key="idx"
        @click="btnClicked(mode.value)"
      >
        {{ mode.text }}
      </v-list-item>
    </v-list>
  </AppMenuBtn>
</template>

<script>
import AppMenuBtn from "@/components/AppMenuBtn";
import {
  mmpiLbIdRange,
  nuclearLbIdRange,
  reportModeSelectList,
  mmpiReportModeSelectList,
  nuclearReportModeSelectList
} from "@/utils/constants/report";

export default {
  components: {
    AppMenuBtn
  },

  props: {
    reportMode: {
      type: String,
      required: true
    },
    lbId: {
      type: Number,
      required: true
    },
    btnClass: {
      type: String
    },
    color: {
      type: String
    },
    tooltip: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    }
  },

  model: {
    prop: "reportMode",
    event: "change"
  },

  data() {
    return {
      localReportMode: this.reportMode,
      reportModeList: reportModeSelectList,
      mmpiLbIdList: mmpiLbIdRange,
      mmpiReportModeList: mmpiReportModeSelectList,
      nuclearLbIdList: nuclearLbIdRange,
      nuclearReportModeList: nuclearReportModeSelectList
    };
  },

  watch: {
    reportMode(newVal) {
      this.localReportMode = newVal;
    },
    localReportMode(newVal) {
      this.$emit("change", newVal);
    }
  },

  methods: {
    btnClicked(reportModeClicked) {
      if (reportModeClicked) {
        this.localReportMode = reportModeClicked;
        this.$nextTick(() => {
          this.$emit("click", reportModeClicked);
        });
      }
    }
  }
};
</script>
