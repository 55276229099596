<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-dialog v-model="isShowDialog" max-width="800px" style="max-height:100%;">
      <v-card>
        <GroupReportDisplayer
          v-if="displayerType === 'group'"
          :case-guid-list="caseGuidList"
        />
        <ReportDisplayer
          v-else-if="displayerType === 'admin'"
          :case-guid="caseGuidList[0]"
          :mode="reportMode"
          :reporter-candidates="reporterCandidateList"
        />
        <ReportAnswerDisplayer
          v-else-if="displayerType === 'answer'"
          :case-guid="caseGuidList[0]"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogClosed">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import ReportDisplayer from "@/components/report/ReportDisplayer";
import GroupReportDisplayer from "@/components/report/GroupReportDisplayer";
import ReportAnswerDisplayer from "@/components/report/ReportAnswerDisplayer";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { fetchReportSignInfo } from "@/api/reportInfo";
import { fetchNodeGuidsForReportSign } from "@/api/case";

export default {
  components: {
    ReportDisplayer,
    GroupReportDisplayer,
    ReportAnswerDisplayer,
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    displayerType: {
      type: String,
      required: true
    },
    caseGuidList: {
      type: Array,
      required: true
    },
    reportMode: {
      type: String
    }
  },

  data() {
    return {
      isShowDialog: this.value,
      isLoading: false,
      errorMsg: "",
      reporterCandidateList: []
    };
  },

  model: {
    prop: "value",
    event: "change"
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.fetchReportSignConfig();
      } else {
        this.dialogClosed();
      }
    }
  },

  methods: {
    dialogClosed() {
      this.$emit("closed");
    },
    async fetchReportSignConfig() {
      if (this.caseGuidList && this.caseGuidList.length) {
        try {
          this.isLoading = true;
          let nodeGuidList = await fetchNodeGuidsForReportSign(
            this.caseGuidList
          );
          if (nodeGuidList && nodeGuidList.length) {
            let signInfo = await fetchReportSignInfo(nodeGuidList[0]);
            this.reporterCandidateList = signInfo.reporterCandidates;
          }
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    }
  },

  created() {
    this.fetchReportSignConfig();
  }
};
</script>
