import { init } from "echarts";

let curveChart = null;

export function drawGroupReportCharts(
  chartDOM,
  {
    chartTitle,
    chartSubtext,
    xAxisData,
    yAxisLabel,
    seriesData,
    visualMarkData,
    tooltipPosition,
    tooltipCallback
  }
) {
  curveChart = init(chartDOM);
  var chartOptions = {
    title: {
      text: chartTitle,
      subtext: chartSubtext
    },
    grid: {
      top: 80
    },
    toolbox: {
      show: true,
      right: 40,
      feature: {
        saveAsImage: {
          title: "保存为图片"
        }
      }
    },
    tooltip: {
      trigger: "axis",
      triggerOn: "mousemove",
      alwaysShowContent: false,
      axisPointer: {
        type: "shadow"
      },
      position: tooltipPosition,
      formatter: tooltipCallback
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xAxisData
    },
    yAxis: {
      type: "value",
      axisPointer: {
        snap: true
      },
      axisLabel: {
        formatter: yAxisLabel
      }
    },
    visualMap: {
      type: "piecewise",
      show: false,
      dimension: 0,
      borderWidth: 1,
      pieces: $_buildVisualMapPieces(
        visualMarkData.blockWidthList,
        visualMarkData.blockColorList
      )
    },
    series: [
      {
        type: "line",
        smooth: true,
        data: seriesData,
        markArea: {
          itemStyle: {
            color: "rgba(0, 0, 0, 0)"
          },
          data: $_buildMarkAreaList(
            visualMarkData.blockWidthList,
            visualMarkData.blockLabelList
          )
        }
      }
    ]
  };
  curveChart.setOption(chartOptions);
}

export function disposeChart() {
  // 清空原有图像
  if (curveChart) {
    curveChart.dispose();
  }
}

function $_buildVisualMapPieces(blockWidthList, blockColorList) {
  let pieces = [];
  let cursor = -1;
  // 如，blockWidth 为 2,3,5
  // 则，输出范围为：(-1,1], (1,4], (4,9]
  for (let i in blockWidthList) {
    pieces.push({
      gt: cursor,
      lte: cursor + blockWidthList[i],
      color: blockColorList[i]
    });
    cursor += blockWidthList[i];
  }
  return pieces;
}

function $_buildMarkAreaList(blockWidthList, blockLabelList) {
  let areaList = [];
  let cursor = 0;
  for (let i in blockWidthList) {
    areaList.push([
      { name: blockLabelList[i], xAxis: cursor },
      { xAxis: cursor + blockWidthList[i] - 1 }
    ]);
    cursor += blockWidthList[i];
  }
  return areaList;
}
