<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-subheader>
      答题账号
      <v-spacer></v-spacer>
      <!-- 如果没有 identity，则显示添加按钮 -->
      <AppMenuBtn
        v-if="!identityDisabled"
        color="primary"
        icon="mdi-plus-circle"
        tooltip="添加登录"
      >
        <v-list>
          <v-list-item
            v-for="idType in identityTypes"
            :key="idType.value"
            @click="applyTypeToIdentity(idType)"
          >
            {{ idType.text }}
          </v-list-item>
        </v-list>
      </AppMenuBtn>
    </v-subheader>
    <v-divider></v-divider>
    <v-slide-y-transition>
      <div v-if="hasLoginIdentity">
        <v-text-field
          :disabled="identityDisabled"
          :label="localPersonInfo.loginIdentity.loginKeyType.typeText"
          hide-details="auto"
          v-model="localPersonInfo.loginIdentity.loginKey"
          :rules="
            buildLogkinKeyRules(localPersonInfo.loginIdentity.loginKeyType)
          "
        ></v-text-field>
        <div class="d-flex align-end">
          <v-text-field
            :disabled="identityDisabled || localPersonInfo.hasPassword"
            type="password"
            label="登录密码"
            hide-details="auto"
            v-model="localPersonInfo.password"
            :rules="fieldRules.userNewPwd"
          ></v-text-field>
          <AppTooltipBtn
            v-if="!identityDisabled && localPersonInfo.hasPassword"
            color="primary"
            icon="mdi-lock-reset"
            :tooltip="passwordResetLabel"
            @click="startChangePassword"
          />
        </div>
      </div>
    </v-slide-y-transition>
    <v-subheader class="mt-6">基本信息</v-subheader>
    <v-divider></v-divider>
    <template v-for="field in localFieldConfigList">
      <div
        v-if="!field.valueRange.length && field.isVisible"
        :key="field.guid"
        class="d-flex align-end"
      >
        <v-text-field
          :label="field.fieldAlias"
          hide-details="auto"
          v-model="localPersonInfo[field.fieldName]"
          :rules="buildTextFieldRules(field)"
          :disabled="
            !isCreatingNew && localDisabledFields.includes(field.fieldName)
          "
        >
        </v-text-field>
      </div>
      <v-select
        v-if="!!field.valueRange.length && field.isVisible"
        :key="field.guid"
        :label="field.fieldAlias"
        hide-details="auto"
        :items="field.valueRange"
        v-model="localPersonInfo[field.fieldName]"
        :rules="field.isRequired ? fieldRules.required : []"
        :disabled="
          !isCreatingNew && localDisabledFields.includes(field.fieldName)
        "
        @change="
          personFieldChanged(field.fieldName, localPersonInfo[field.fieldName])
        "
      ></v-select>
    </template>
    <CredentialsPasswordDialog
      v-model="isShowChangePasswordDialog"
      :user-guid="localPersonInfo.userGuid"
      :is-new-pwd="!localPersonInfo.hasPassword"
      @closed="closeChangePassword"
    />
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppMessageBox from "@/components/AppMessageBox";
import CredentialsPasswordDialog from "@/components/CredentialsPasswordDialog";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { fetchVisibleIdentityTypeList } from "@/api/userIdentity";

export default {
  components: {
    AppLoadingSpinner,
    AppTooltipBtn,
    AppMenuBtn,
    AppMessageBox,
    CredentialsPasswordDialog
  },

  props: {
    isCreatingNew: {
      type: Boolean,
      default: false
    },
    nodeGuids: {
      type: Array,
      required: true
    },
    regionGuid: {
      type: String,
      required: true
    },
    personInfo: {
      type: Object,
      required: true
    },
    disabledFields: {
      type: Array
    },
    identityDisabled: {
      type: Boolean,
      default: false
    },
    noUpdateFields: {
      type: Array
    },
    fieldConfigList: {
      type: Array
    },
    nodeList: {
      type: Array
    }
  },

  model: {
    prop: "personInfo",
    event: "changed"
  },

  data() {
    return {
      isLoading: false,
      localPersonInfo: this.personInfo,
      localDisabledFields: this.disabledFields || [],
      localNoUpdateFields: this.noUpdateFields || [],
      localFieldConfigList: this.fieldConfigList || [],
      localNodeList: this.nodeList || [],
      identityTypes: [],
      fieldRules: {
        userNewPwd: [
          val =>
            (val || "").length < 1 ||
            (val || "").length >= 6 ||
            "新密码不能少于6位"
        ],
        required: [val => (`${val}` || "").length > 0 || "必填"],
        age: [val => /^\d{1,3}$/.test(val) || "请输入正确的年龄"]
      },
      // dialog
      isShowChangePasswordDialog: false,
      errorMsg: ""
    };
  },

  watch: {
    personInfo: {
      handler(newVal) {
        this.localPersonInfo = newVal;
        // 设置密码TextField的显示
        if (this.localPersonInfo.hasPassword) {
          this.localPersonInfo.password = "this is hidden password";
        }
      },
      immediate: true,
      deep: true
    },
    localPersonInfo: {
      handler(newVal) {
        this.$emit("changed", newVal);
      },
      immediate: true,
      deep: true
    },
    "localPersonInfo.nodeGuid": {
      handler(newVal) {
        this.personFieldChanged("nodeGuid", newVal);
      },
      immediate: true
    },
    noUpdateFields(newVal) {
      this.localNoUpdateFields = newVal;
    },
    localNoUpdateFields(newVal) {
      this.$emit("update:no-update-fields", newVal);
    },
    fieldConfigList(newVal) {
      this.localFieldConfigList = newVal;
    },
    nodeList(newVal) {
      this.localNodeList = newVal;
    }
  },

  computed: {
    hasLoginIdentity() {
      return (
        this.localPersonInfo.loginIdentity &&
        this.localPersonInfo.loginIdentity.loginKeyType &&
        this.localPersonInfo.loginIdentity.loginKeyType.typeValue
      );
    },
    passwordResetLabel() {
      return this.localPersonInfo.hasPassword ? "修改密码" : "添加新密码";
    }
  },

  methods: {
    isNodeListFound(nodeList) {
      return nodeList && nodeList.length;
    },
    buildLogkinKeyRules(loginKeyType) {
      if (loginKeyType && loginKeyType.rulesRegex) {
        return [
          val => {
            let regPattern = new RegExp(loginKeyType.rulesRegex);
            return regPattern.test(val) || loginKeyType.rulesMessage;
          }
        ];
      }
      return this.fieldRules.required;
    },
    buildTextFieldRules(fieldObj) {
      let ruleRegex = [
        val => {
          // 特殊情况：age，范围 1 ~ 130
          if (fieldObj.fieldName === "age") {
            return (
              new RegExp("^([1-9]\\d?|1[0-2]\\d|130)$").test(val) ||
              "请输入正确的年龄"
            );
          }
          // 对于 secured 的字段，由于会加星号，所以不校验
          // 对于没有设置 regex 的 field，不校验直接通过
          let regPattern = new RegExp(fieldObj.rulesRegex);
          return regPattern.test(val) || fieldObj.rulesMessage;
        }
      ];
      if (fieldObj.isRequired) {
        return [...this.fieldRules.required, ...ruleRegex];
      }
      return ruleRegex;
    },
    // ====================== Change password ===============================
    startChangePassword() {
      this.isShowChangePasswordDialog = true;
    },
    closeChangePassword() {
      this.isShowChangePasswordDialog = false;
    },
    // ====================== Add Identity ===============================
    applyTypeToIdentity(identityType) {
      this.localPersonInfo.loginIdentity.loginKeyType = {
        typeValue: identityType.value,
        typeText: identityType.text,
        rulesRegex: identityType.rulesRegex,
        rulesMessage: identityType.rulesMessage
      };
    },
    // ====================== Prepare data ===============================
    async getFieldConfig() {
      try {
        this.isLoading = true;
        this.localFieldConfigList = await getUserFieldConfig(this.nodeGuids);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getlocalNodeList() {
      try {
        this.isLoading = true;
        this.localNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getVisibleIdentityTypes() {
      try {
        this.isLoading = true;
        let visibleIdTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          null,
          this.nodeGuids
        );
        if (!visibleIdTypes || !visibleIdTypes.length) {
          this.errorMsg = "尚未添加任何识别号类型，请联系管理员进行添加。";
        }
        this.identityTypes = visibleIdTypes;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    prepareFieldConfig() {
      if (!this.localFieldConfigList || !this.localFieldConfigList.length) {
        return this.getFieldConfig();
      }
    },
    preparelocalNodeList() {
      if (!this.localNodeList || !this.localNodeList.length) {
        return this.getlocalNodeList();
      }
    },
    async personFieldChanged(fieldName, newVal) {
      if (fieldName === "nodeGuid") {
        let node = this.localNodeList.find(d => d.value === newVal);
        if (node) {
          this.localPersonInfo.nodeName = node.text;
        } else {
          this.localPersonInfo.nodeName = "";
        }
      }
    }
  },

  async created() {
    // 需要先加载 fieldConfig
    await this.prepareFieldConfig();
    // 初始状态下，secured 的 fields 就是不需要 update 的 fields
    // NodeSelectList
    await this.preparelocalNodeList();
    // IdentityTypes
    await this.getVisibleIdentityTypes();
    if (this.isNodeListFound(this.localNodeList)) {
      let nodeConfig = this.localFieldConfigList.find(
        fc => fc.fieldName === "nodeGuid"
      );
      nodeConfig.valueRange = this.localNodeList;
    }
    // created 时会是 personInfo 的 nodeName 为空，在这里补上
    this.personFieldChanged("nodeGuid", this.localPersonInfo.nodeGuid);
  }
};
</script>
