import { get, post } from "@/utils/axios.js";

export function getLbListWithCategory(nodeGuids) {
  return post("/lbmanage/LbInfoWithCategory", nodeGuids);
}

export function getLbDetails(lbGuid) {
  return get(`/lbmanage/LbDetails/${lbGuid}`);
}

export function getLbCategoryList(regionGuid) {
  return get(`/lbmanage/AllLbCategoryList/${regionGuid}`);
}

export function getLbScoreFieldNames(lbGuid) {
  return get(`/lbmanage/LbScoreFieldNames/${lbGuid}`);
}

export function updateLbDetails({
  lbGuid,
  dispName,
  timeBoxSecond,
  isTopMost,
  isQuesShuffle,
  isShowProgress
}) {
  return post("/lbmanage/UpdateLbDetails", {
    lbGuid,
    dispName,
    timeBoxSecond,
    isTopMost,
    isQuesShuffle,
    isShowProgress
  });
}

export function getLbAlerts(lbGuid) {
  return get(`/lbalert/LbAlerts/${lbGuid}`);
}

export function getAlertNamesForFilter(
  regionGuid,
  nodeGuidList,
  testStatusList
) {
  return post("/lbalert/AlertNamesFromNodeAndCaseStatus", {
    customerGuid: regionGuid,
    nodeGuidList,
    testStatusList
  });
}

export async function updateLbAlertDisp({
  alertDispGuid,
  displayName,
  colorHex
}) {
  let response = await post("/lbalert/UpdateLbAlertDisplay", {
    guid: alertDispGuid,
    displayName,
    colorHex
  });
  return response.guid;
}

/**
 * @param {*} conditionList
 * {
 *    alertDispGuid: String,
 *    scoreFieldName: String,
 *    scoreOperator: String,
 *    scoreValue: Number,
 *    logicOrder: Number,
 *    logicOperator: String
 * }
 * @returns
 */
export async function updateLbAlertConditions(conditionList) {
  let response = await post("/lbalert/UpdateLbAlertConditions", conditionList);
  return response.value;
}

export async function removeLbAlertDisp({ guid }) {
  let response = await post("/lbalert/RemoveOneLbAlertDisplay", {
    guid
  });
  return response.value;
}

export async function removeLbAlertConditions(conditionGuidList) {
  let response = await post(
    "/lbalert/RemoveLbAlertConditions",
    conditionGuidList
  );
  return response.value;
}
